import { Action } from 'types/index';

export enum SpecialOffersActionTypes {
  UpdateSpecialOffers = 'SpecialOffers.UpdateSpecialOffers',
}

interface Time {
  start: number;
  end: number;
}

interface ValidWeek {
  day_of_week: number;
  enabled: boolean;
  time: Time;
}

interface ISpecialOffer {
  id: number;
  name: string;
  date: {
    start: number;
    end: number;
  };
  valid_week: ValidWeek[];
}

export type SpecialOffersState = ISpecialOffer[];

export interface SpecialOffersAction extends Action<SpecialOffersState> {
  type: SpecialOffersActionTypes;
}
