import { ISearchReservationsData } from 'types/app/app';
import { mapValues } from 'lodash';
import { searchReservationsFieldNames } from 'app/components/NewSidebar/_components/SidebarAdvancedSearch/_config';

export const prepareSearchReservationsData = (data: ISearchReservationsData) => {
  const defaults = {
    limit: data.limit || 10,
    page: data.page || 1,
  };

  return mapValues({ ...data, ...defaults }, (value, key) => {
    if (value === '' || value === null) {
      return undefined;
    }
    if (key === searchReservationsFieldNames.phone && typeof value === 'string') {
      return value.replace(/\s+/g, '');
    }
    if (key === searchReservationsFieldNames.reservation_id) {
      return Number(value);
    }
    return value;
  }) as ISearchReservationsData;
};
