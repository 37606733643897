import { Action } from 'types/index';

export enum ConfigActionTypes {
  Loaded = 'Config.Loaded',
}

export interface IOption {
  value: string;
  label: string;
}

export interface INumberOption {
  value: number;
  label: string;
}

export type ConfigState = {
  reservation_confirmations: IOption[];
  reservation_tags: {
    additional_info: IOption[];
    dietary_requirements: IOption[];
  };
  reservation_durations: IOption[];
  client_tags: IOption[];
  languages: IOption[];
  system_languages: IOption[];
  widget_languages: IOption[];
  week_days: IOption[];
  first_day_of_week: IOption[];
  user_roles: IOption[];
  permissions: IOption[];
  user_statuses: IOption[];
  timezones: IOption[];
  date_formats: IOption[];
  user_restaurants: IOption[];
  account_restaurants: IOption[];
  countries: IOption[];
  policies_and_terms: {
    [key: string]: IOption[];
  };
  available_widget_languages: IOption[];
};

export interface ConfigLoadedAction extends Action<ConfigState> {
  type: ConfigActionTypes.Loaded;
}

export type ConfigAction = ConfigLoadedAction;
