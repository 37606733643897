import { Action } from 'types/index';
import { SelectOption } from 'app/components/FormElements/MuiSelect';

export enum UsersActionTypes {
  Loaded = 'Users.Loaded',
}

export enum UserStatuses {
  Inactive = 'INACTIVE',
  Active = 'ACTIVE',
  Deleted = 'DELETED',
}

export enum UserRoles {
  Admin = 'ADMIN',
  Owner = 'OWNER',
  Manager = 'MANAGER',
  Staff = 'STAFF',
  ReadOnly = 'READ_ONLY',
  Marketing = 'MARKETING',
}

export enum UserPermissions {
  Calendar = 'calendar',
  List = 'list',
  FloorPlan = 'floor_plan',
  Database = 'database',
  DatabaseReadOnly = 'database_read_only',
  RestaurantInformation = 'restaurant_information',
  TimeLanguage = 'time_language',
  ManagerAccount = 'manager_account',
  Users = 'users',
  DiningRoomsAndAreas = 'dining_rooms_and_areas',
  TableGroups = 'table_groups',
  FloorPlanSetup = 'floor_plan_setup',
  OpeningHours = 'opening_hours',
  CustomPeriod = 'custom_periods',
  CalendarReservationsSettings = 'calendar_reservations_settings',
  SidebarSettings = 'sidebar_settings',
  EmailSettings = 'email_settings',
  SmsSettings = 'sms_settings',
  WidgetSettings = 'widget_settings',
  WidgetFields = 'widget_fields',
  PolicesAndTerms = 'polices_and_terms',
  WidgetIntegrations = 'widget_integrations',
  MarketingChannels = 'marketing_channels',
  Experiences = 'experiences',
  Events = 'events',
  SpecialOffers = 'special_offers',
  PrepaymentsAndDeposits = 'prepayments_and_deposits',
  Integrations = 'integrations',
  Banners = 'banners',
  RestaurantProfile = 'restaurant_profile',
  Gallery = 'gallery',
  MenuSettings = 'menu_settings',
  MenuEditor = 'menu_editor',
  SubscriptionsAndBilling = 'subscriptions_and_billing',
  Invoices = 'invoices',
}

export type IUser = {
  id: number;
  name: string;
  email: string;
  language?: string;
  status: UserStatuses;
  role: UserRoles;
  permissions: string[];
  restaurants: number[];
  waiters: SelectOption[];
  tfa?: {
    status: boolean;
    channel: string;
    phone: string;
  };
};

export type UsersData = IUser[];

interface Pagination {
  total: number;
  per_page: number;
  current_page: number;
  last_page: number;
  data: any;
}

export interface UsersState extends Pagination {
  data: UsersData;
}

export interface UsersAction extends Action<UsersState> {
  type: UsersActionTypes;
}
