import { flags } from 'constants/flags';

export type FlagCode = keyof typeof flags;

export const handleCountryFlag = (code: FlagCode | undefined) => {
  if (code === undefined) {
    return null;
  }

  return flags[code];
};
