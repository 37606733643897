const constants = {
  VARIABLES: {
    tableinSidebarOpen: 'tableinSidebarOpen',
    tableinCalendarDisplay: 'tableinCalendarDisplay',
    tableinCalendarDayStart: 'tableinCalendarDayStart',
    showDaysLength: 7,
    paginationLimit: 10,
  },
};

export default constants;
