import { Action } from 'types/index';
import { IUser } from 'types/app/users';

interface IRestaurant {
  label: string;
  value: number;
}

interface IDrupalPermissions {
  access_manager_pages?: boolean;
  access_user_has_staff_permissions?: boolean;
  access_dashboard?: boolean;
  restaurant_can_create_new_restaurant?: boolean;
  access_restaurant_sub_manager?: boolean;
  access_web_admin?: boolean;
  access_settings_pages?: boolean;
  access_hubspot_chat?: boolean;
  permission_to_delete?: boolean;
  read_only?: boolean;
}

export type IProfile = IUser & {
  first_name?: string;
  last_name?: string;
  phone?: string;
  restaurants: IRestaurant[];
  drupal_roles: string[];
  drupal_permissions: IDrupalPermissions;
};

export enum ProfileActionTypes {
  Loaded = 'Profile.Loaded',
}

export type ProfileState = IProfile;

export interface ProfileAction extends Action<ProfileState> {
  type: ProfileActionTypes;
}
