import { DaysOfTheWeek } from 'enums/daysOfTheWeek';
import { AvailableLanguages } from 'enums/availableLanguages';
import { Action } from 'types/index';
import { DateFormatValues } from 'enums/dates';

export enum TimeAndLanguageActionTypes {
  Loaded = 'TimeAndLanguage.Loaded',
}

export interface ITimeAndLanguageData {
  system_language: string;
  time_zone: string;
  date_format: DateFormatValues;
  time_zone_offset: number;
  first_day_of_week: DaysOfTheWeek;
  widget_default_language: AvailableLanguages;
  widget_available_languages: AvailableLanguages[];
}

export interface TimeAndLanguageAction extends Action<ITimeAndLanguageData> {
  type: TimeAndLanguageActionTypes;
}
