import { Action } from 'types/index';

export enum NotificationSettingsActionTypes {
  Loaded = 'NotificationSettings.Loaded',
}

export enum NotificationStatus {
  INACTIVE = 'INACTIVE',
  ACTIVE = 'ACTIVE',
}

export interface IClientNotifyTypes {
  confirmation: boolean;
  reminder: boolean;
  feedback: boolean;
  no_show: boolean;
  table_ready: boolean;
}

export interface IStaffNotifyTypes {
  reservation_received: boolean;
  reservation_cancelled: boolean;
}

export interface INotificationSMSSettings {
  status: NotificationStatus;
  sender_name: string;
  client_notify_types: IClientNotifyTypes;
  staff_notify_types: IStaffNotifyTypes;
}

export interface INotificationSettingsData {
  sms: INotificationSMSSettings;
}

export interface NotificationSettingsAction extends Action<INotificationSettingsData> {
  type: NotificationSettingsActionTypes;
}
