interface FlexBasisStyleOptions {
  startingCount: number;
  gap: number;
  incrementStep: number;
  maxCount: number;
}

export const generateFlexBasisStyles = ({
  startingCount,
  gap,
  incrementStep,
  maxCount,
}: FlexBasisStyleOptions) => {
  const styles: any = {
    flex: `0 1 calc((100% - ${(startingCount - 1) * gap}px) / ${startingCount})`,
  };

  let minWidth = 480;
  for (let count = startingCount + 1; count <= maxCount; count++) {
    const mediaQuery = `@media (min-width: ${minWidth}px)`;
    styles[mediaQuery] = {
      flex: `0 1 calc((100% - ${(count - 1) * gap}px) / ${count})`,
    };
    minWidth += incrementStep;
  }

  return styles;
};
