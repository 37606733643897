import { Action } from 'types/index';

export enum TablesActionTypes {
  UpdateTables = 'RoomsAndTables.UpdateTables',
}

export interface ITable {
  id: number;
  name: string;
  min_guests: number;
  max_guests: number;
  booking_length: number;
  room_id: number;
  group_ids: number[];
  online_booking: boolean;
  weight: number;
  status: string;
  shape: string | null;
}

export type TablesState = ITable[];

export interface TablesAction extends Action<TablesState> {
  type: TablesActionTypes;
}
