import moment, { Duration } from 'moment';
import { CalendarState } from 'types/calendar';
import { DateFormatKey } from 'types/dates';

interface MathMethod {
  ceil: string;
}

export const datesMoment: DateFormatKey = {
  dmy: 'DD/MM/YYYY', //'d/m/Y H:i:s',
  dmya: 'DD/MM/YYYY', //'d/m/Y h:i:s A',
  mdy: 'MM/DD/YYYY', //'m/d/Y H:i:s',
  mdya: 'MM/DD/YYYY', //'m/d/Y h:i:s A',
  ymd: 'YYYY-MM-DD', //'Y-m-d H:i:s',
};

const times: DateFormatKey = {
  dmy: 'HH:mm', //'d/m/Y H:i:s',
  dmya: 'hh:mm A', //'d/m/Y h:i:s A',
  mdy: 'HH:mm', //'m/d/Y H:i:s',
  mdya: 'hh:mm A', //'m/d/Y h:i:s A',
  ymd: 'HH:mm', //'Y-m-d H:i:s',
};

const hours: DateFormatKey = {
  dmy: 'HH', //'d/m/Y H:i:s',
  dmya: 'hh A', //'d/m/Y h:i:s A',
  mdy: 'HH', //'m/d/Y H:i:s',
  mdya: 'hh A', //'m/d/Y h:i:s A',
  ymd: 'HH', //'Y-m-d H:i:s',
};

export const dateFormatDate = (formatKey?: keyof DateFormatKey) => {
  return typeof formatKey !== 'undefined' && datesMoment[formatKey] !== 'undefined'
    ? datesMoment[formatKey]
    : datesMoment['dmy'];
};

export const timeFormat = (formatKey: keyof DateFormatKey) => {
  return times[formatKey] !== 'undefined' ? times[formatKey] : times['dmy'];
};

export const hourFormat = (formatKey: keyof DateFormatKey) => {
  return hours[formatKey] !== 'undefined' ? hours[formatKey] : hours['dmy'];
};

export const DateTimeFormat = (formatKey: keyof DateFormatKey) => {
  return (
    (datesMoment[formatKey] !== 'undefined' ? datesMoment[formatKey] : datesMoment['dmy']) +
    ' - ' +
    (times[formatKey] !== 'undefined' ? times[formatKey] : times['dmy'])
  );
};

export const roundDate = (date: number, duration: Duration, method: keyof MathMethod) => {
  return moment(Math[method](+date / +duration) * +duration).valueOf();
};

export const calculateOpenHoursInDay = (date: moment.Moment, store: CalendarState) => {
  let data: any = [];
  const weekday = date.day();

  if (store.restaurant.settings && store.restaurant.settings.open_hours[weekday]) {
    data = { ...store.restaurant.settings.open_hours[weekday] };
  } else if (store.restaurant.settings && store.restaurant.settings.open_hours['d' + weekday]) {
    data = { ...store.restaurant.settings.open_hours['d' + weekday] };
  }
  const exclusive_day = store.restaurant.settings.exclusive_days.find(
    (el) => el.date === date.format('YYYY-MM-DD'),
  );
  if (exclusive_day) {
    data = exclusive_day;
    data.open.from = moment(data.open.from || moment(), ['HH:mm:ss']);
    data.open.to = moment(data.open.to || moment(), ['HH:mm:ss']);
  }
  const start = moment(data.open.from || moment()).set({
    year: date.get('year'),
    month: date.get('month'),
    date: date.get('date'),
  });
  const end = moment(data.open.to || moment()).set({
    year: date.get('year'),
    month: date.get('month'),
    date: date.get('date'),
  });
  if (end.isSameOrBefore(start)) {
    end.add('+1', 'day');
  }
  return { data, start, end };
};
