export const timestamp = (timestamp: number) => {
  return {
    get toMilliseconds() {
      if (timestamp.toString().length === 10) {
        return timestamp * 1000;
      }

      return timestamp;
    },

    get toSeconds() {
      if (timestamp.toString().length === 13) {
        return timestamp / 1000;
      }
      return timestamp;
    },

    get addDay() {
      return timestamp + 86400;
    },

    ceilToInterval: (interval: number) => {
      const offset = timestamp.toString().length === 13 ? 1000 : 1;
      const diff = timestamp % (interval * offset);

      if (diff === 0) {
        return timestamp;
      }

      return timestamp + (interval * offset - diff);
    },
  };
};
