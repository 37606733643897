export enum TableManagementActionTypes {
  GetRestaurantLayouts = 'TableManagement.GetRestaurantLayouts',
  CreateRestaurantLayout = 'TableManagement.CreateRestaurantLayout',
  UpdateRestaurantLayout = 'TableManagement.UpdateRestaurantLayout',
  DeleteRestaurantLayout = 'TableManagement.DeleteRestaurantLayout',
  GetRestaurantLayoutWithRooms = 'TableManagement.GetRestaurantLayoutWithRooms',
  UpdateRestaurantLayouts = 'TableManagement.UpdateRestaurantLayouts',
  CreateRestaurantRoom = 'TableManagement.CreateRestaurantRoom',
  UpdateRestaurantRoom = 'TableManagement.UpdateRestaurantRoom',
  UpdateRestaurantRoomsOrder = 'TableManagement.UpdateRestaurantRoomsOrder',
  CreateRestaurantTable = 'TableManagement.CreateRestaurantTable',
  UpdateRestaurantTable = 'TableManagement.UpdateRestaurantTable',

  GetTableGroups = 'TableManagement.GetTableGroups',
}

export interface RestaurantLayout {
  id?: number;
  name: string;
  online_booking: boolean;
  active: boolean;
  weight: number;
  rooms?: IRoom[];
}

export interface ITable {
  id?: number;
  status: string;
  name: string;
  min_guests: number;
  max_guests: number;
  room_id: number;
  group_ids: number[];
  online_booking: boolean;
  weight: number;
  shape: string | null;
  booking_length?: number;
}

export interface IRoom {
  id?: number;
  status: string;
  layout_id: number;
  name: string;
  online_booking: boolean;
  collapsed: boolean;
  weight: number;
  tables?: ITable[];
}

export interface ITableGroup {
  id: number;
  name: string;
  min_guests: number;
  max_guests: number;
  table_ids: number[];
  weight: number;
}

export interface ITableGroupData {
  id: number;
  name: string;
  min_guests: number;
  max_guests: number;
  table_ids: number[];
  weight: number;
}

export type TableManagementState = {
  layouts: RestaurantLayout[];
  groups: ITableGroup[];
};

interface GetRestaurantLayoutsAction {
  type: TableManagementActionTypes.GetRestaurantLayouts;
  payload: RestaurantLayout[];
}

interface CreateRestaurantLayoutAction {
  type: TableManagementActionTypes.CreateRestaurantLayout;
  payload: RestaurantLayout;
}

interface UpdateRestaurantLayoutAction {
  type: TableManagementActionTypes.UpdateRestaurantLayout;
  payload: RestaurantLayout;
}

interface DeleteRestaurantLayoutAction {
  type: TableManagementActionTypes.DeleteRestaurantLayout;
  payload: number;
}

interface GetRestaurantLayoutWithRoomsAction {
  type: TableManagementActionTypes.GetRestaurantLayoutWithRooms;
  payload: RestaurantLayout;
}

interface UpdateRestaurantLayoutsAction {
  type: TableManagementActionTypes.UpdateRestaurantLayouts;
  payload: RestaurantLayout[];
}

interface CreateRestaurantRoomAction {
  type: TableManagementActionTypes.CreateRestaurantRoom;
  payload: IRoom;
}

interface UpdateRestaurantRoomAction {
  type: TableManagementActionTypes.UpdateRestaurantRoom;
  payload: IRoom;
}

interface CreateRestaurantTableAction {
  type: TableManagementActionTypes.CreateRestaurantTable;
  payload: ITable;
}

interface UpdateRestaurantTableAction {
  type: TableManagementActionTypes.UpdateRestaurantTable;
  payload: ITable;
}

interface GetTableGroupsAction {
  type: TableManagementActionTypes.GetTableGroups;
  payload: ITableGroup[];
}

interface UpdateRestaurantRoomsOrderAction {
  type: TableManagementActionTypes.UpdateRestaurantRoomsOrder;
  payload: number[];
}

export type TableManagementAction =
  | GetRestaurantLayoutsAction
  | CreateRestaurantLayoutAction
  | UpdateRestaurantLayoutAction
  | DeleteRestaurantLayoutAction
  | GetRestaurantLayoutWithRoomsAction
  | UpdateRestaurantLayoutsAction
  | CreateRestaurantRoomAction
  | UpdateRestaurantRoomAction
  | CreateRestaurantTableAction
  | UpdateRestaurantTableAction
  | GetTableGroupsAction
  | UpdateRestaurantRoomsOrderAction;
