// removes properties with null/undefined/empty value from object
import { FloorPlanRoom } from 'types/floorPlan';

interface FilterRoomProp {
  tables: string;
}
export function clean(obj: object) {
  for (const propName in obj) {
    // @ts-ignore
    if (obj[propName] === null || obj[propName] === undefined || obj[propName] === '') {
      // @ts-ignore
      delete obj[propName];
    }
  }
  return obj;
}

export function filterObject(obj: FloorPlanRoom, prop: keyof FilterRoomProp) {
  if (!!obj && typeof obj === 'object' && obj[prop]) {
    const newObj = { ...obj };
    delete newObj[prop];
    return newObj;
  }
  return obj;
}
