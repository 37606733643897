import { BookingStatusGroups, Statuses } from 'types/reservation';

export interface IReservationColors {
  firstColor: string;
  secondColor: string;
}

export const getStatusColors = (
  status: Statuses,
  isPastReservation: boolean,
): IReservationColors => {
  const colors = {
    [Statuses.Arrived]: { firstColor: 'accent.green', secondColor: 'green1' },
    [Statuses.Blocked]: { firstColor: 'accent.red', secondColor: 'pigPink' },
    [Statuses.Cancelled]: { firstColor: 'accent.gray', secondColor: 'gray16' },
    [Statuses.Confirmed]: isPastReservation
      ? { firstColor: 'accent.green', secondColor: 'green1' }
      : { firstColor: 'accent.blue', secondColor: 'blue1' },
    [Statuses.NoShow]: { firstColor: 'accent.red', secondColor: 'pigPink' },
    [Statuses.Pending]: { firstColor: 'accent.yellow', secondColor: 'yellow11' },
    [Statuses.Template]: { firstColor: 'accent.gray', secondColor: 'gray16' },
    [Statuses.WalkIn]: { firstColor: 'accent.violet', secondColor: 'violet1' },
    [Statuses.WaitingList]: { firstColor: 'accent.brown', secondColor: 'brown2' },
    [Statuses.None]: { firstColor: 'accent.gray', secondColor: 'gray16' },
  };
  return colors[status] || { firstColor: 'accent.blue', secondColor: 'blue1' };
};

export const getStatusGroupColor = (groupName: BookingStatusGroups) => {
  const colors = {
    [BookingStatusGroups.Reservation]: { firstColor: 'accent.blue', secondColor: 'blue1' },
    [BookingStatusGroups.WalkIn]: { firstColor: 'accent.violet', secondColor: 'violet1' },
    [BookingStatusGroups.Event]: { firstColor: 'accent.yellow', secondColor: 'yellow11' },
    [BookingStatusGroups.Blocked]: { firstColor: 'accent.red', secondColor: 'pigPink' },
    [BookingStatusGroups.WaitingList]: { firstColor: 'accent.brown', secondColor: 'brown2' },
    [BookingStatusGroups.Template]: { firstColor: 'accent.gray', secondColor: 'gray16' },
  };
  return colors[groupName] || { firstColor: 'accent.blue', secondColor: 'blue1' };
};
