import { Action } from 'types/index';

export enum OpeningHoursActionTypes {
  Loaded = 'OpeningHours.Loaded',
}

interface IBlockedTime {
  room_id: number;
  times: number[];
}

export interface IOpeningHour {
  is_closed: boolean;
  day_of_week?: number;
  date?: {
    start: number;
    end: number;
  };
  time: {
    start: number;
    end: number;
    rounded_start: number;
    rounded_end: number;
  };
  blocked_times: IBlockedTime[];
}

export type OpeningHoursState = IOpeningHour[];

export interface OpeningHoursAction extends Action<OpeningHoursState> {
  type: OpeningHoursActionTypes;
}
