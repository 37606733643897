import { Action } from 'types/index';

export enum RoomsActionTypes {
  UpdateRooms = 'RoomsAndTables.UpdateRooms',
}

export interface IRoom {
  id: number;
  name: string;
  online_booking: boolean;
  weight: number;
  status: string;
  collapsed: boolean;
  layout_id: number;
}

export type RoomsState = IRoom[];

export interface RoomsAction extends Action<RoomsState> {
  type: RoomsActionTypes;
}
