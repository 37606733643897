import { Action } from '.';

export interface NoteDate {
  startDate: string;
  endDate: string;
}
export interface Note {
  id: string;
  restaurand_id: number;
  user_id: number;
  start_date: number;
  end_date: number;
  days: number[];
  color: string;
  name: string;
  text: string;
}

export interface NewNoteParams {
  date: NoteDate;
  days: number[];
  color: string;
  name: string;
  text: string;
}

export interface NotesState {
  isDrawerOpen: boolean;
  createNoteLoading: boolean;
  deleteNoteLoading: boolean;
  getNotesLoading: boolean;
  notes: Note[];
  selectedNote: Note | null;
}

export interface INotesColors {
  primary: string;
  secondary: string;
  info: string;
  success: string;
  danger: string;
}
export enum NotesColors {
  primary = 'accent.blue',
  secondary = 'accent.violet',
  info = 'accent.yellow',
  success = 'accent.green',
  danger = 'accent.red',
}

//Actions

export interface SetIsDrawerOpenAction extends Action<{ isOpen: boolean }> {
  type: 'Notes.setIsDrawerOpen';
}

export interface SetCreateNoteLoadingAction extends Action<{ isLoading: boolean }> {
  type: 'Notes.setCreateNoteLoading';
}

export interface SetDeleteNoteLoadingAction extends Action<{ isLoading: boolean }> {
  type: 'Notes.setDeleteNoteLoading';
}

export interface SetGetNotesLoadingAction extends Action<{ isLoading: boolean }> {
  type: 'Notes.setGetNotesLoading';
}

export interface SetNotesAction extends Action<{ notes: Note[] }> {
  type: 'Notes.setNotes';
}
export interface SetSelectedNoteAction extends Action<{ note: Note | null }> {
  type: 'Notes.setSelectedNote';
}

export type NotesAction =
  | SetIsDrawerOpenAction
  | SetCreateNoteLoadingAction
  | SetDeleteNoteLoadingAction
  | SetGetNotesLoadingAction
  | SetNotesAction
  | SetSelectedNoteAction;
