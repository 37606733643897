export interface TimeManagementRange {
  id?: number;
  max_guests: number | string;
  max_length: number | string;
  min_guests: number | string;
  min_length: number | string;
  weight?: number;
}

export interface DefaultBookingLength {
  ranges: TimeManagementRange[];
}

export interface CustomBookingLength {
  id?: number;
  name: string;
  start_time?: number;
  end_time?: number;
  valid_days?: number[];
  weight?: number;
  ranges: TimeManagementRange[];
}

export enum TimeManagementActionTypes {
  UpdateDefaultBookingLength = 'TimeManagement.UpdateDefaultBookingLength',
  UpdateCustomBookingLength = 'TimeManagement.UpdateCustomBookingLength',
  CreateCustomBookingLength = 'TimeManagement.CreateCustomBookingLength',
  UpdateCustomBookingByIdLength = 'TimeManagement.UpdateCustomBookingByIdLength',
  DeleteCustomBookingByIdLength = 'TimeManagement.DeleteCustomBookingByIdLength',
  GetCustomBookingByIdLength = 'TimeManagement.GetCustomBookingByIdLength',
  UpdateCustomBookingLengthOrder = 'TimeManagement.UpdateCustomBookingLengthOrder',
}

export type TimeManagementState = {
  default_booking_length: DefaultBookingLength;
  custom_booking_length: CustomBookingLength[];
};

interface UpdateBookingLengthAction {
  type: TimeManagementActionTypes.UpdateDefaultBookingLength;
  payload: DefaultBookingLength;
}

interface CreateCustomBookingLengthAction {
  type: TimeManagementActionTypes.CreateCustomBookingLength;
  payload: CustomBookingLength;
}

interface UpdateCustomLengthAction {
  type: TimeManagementActionTypes.UpdateCustomBookingLength;
  payload: CustomBookingLength[];
}

interface UpdateCustomBookingLengthOrder {
  type: TimeManagementActionTypes.UpdateCustomBookingLengthOrder;
  payload: number[];
}

interface UpdateCustomLengthByIdAction {
  type: TimeManagementActionTypes.UpdateCustomBookingByIdLength;
  payload: {
    id: number;
    data: CustomBookingLength;
  };
}

interface DeleteCustomLengthByIdAction {
  type: TimeManagementActionTypes.DeleteCustomBookingByIdLength;
  payload: number;
}

interface GetCustomLengthByIdAction {
  type: TimeManagementActionTypes.GetCustomBookingByIdLength;
  payload: CustomBookingLength;
}

export type TimeManagementAction =
  | UpdateBookingLengthAction
  | CreateCustomBookingLengthAction
  | UpdateCustomLengthAction
  | UpdateCustomLengthByIdAction
  | DeleteCustomLengthByIdAction
  | GetCustomLengthByIdAction
  | UpdateCustomBookingLengthOrder;
