import { enqueueSnackbar } from 'notistack';
import { MessageVariants } from 'enums/notifications';

export const handleErrors = (error: any) => {
  switch (error?.response.status) {
    case 403:
      enqueueSnackbar('You have no access to open this page', { variant: MessageVariants.Error });
      break;
    case 422:
      for (const property in error.response.data.errors) {
        enqueueSnackbar(error.response.data.errors[property], { variant: MessageVariants.Error });
      }
      break;
    default:
      enqueueSnackbar('Something went wrong, please try again later', {
        variant: MessageVariants.Error,
      });
  }
};
