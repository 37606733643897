import { TableinAppState } from 'types/index';

export enum RootActionTypes {
  ResetStoreToDefault = 'Root.ResetStoreToDefault',
}

interface ResetStoreAction {
  type: RootActionTypes.ResetStoreToDefault;
  payload: TableinAppState;
}

export type RootActions = ResetStoreAction;
