import { SelectOption } from 'app/components/FormElements/MuiSelect';
import { TFunction } from 'i18next';

export const getLabelForValue = (
  value: any,
  options: SelectOption[],
  translateLabels: boolean,
  t: TFunction,
) => {
  const selectedOption = options.find((option) => option.value === value);
  const label = selectedOption ? selectedOption.label : '';
  return translateLabels ? t(label) : label;
};
