export enum LoadingActionTypes {
  CreateReservationPending = 'Loading.CreateReservationPending',
  CreateReservationSuccess = 'Loading.CreateReservationSuccess',
  CreateReservationRejected = 'Loading.CreateReservationRejected',

  UpdateReservationPending = 'Loading.UpdateReservationPending',
  UpdateReservationSuccess = 'Loading.UpdateReservationSuccess',
  UpdateReservationRejected = 'Loading.UpdateReservationRejected',

  DeleteReservationPending = 'Loading.DeleteReservationPending',
  DeleteReservationSuccess = 'Loading.DeleteReservationSuccess',
  DeleteReservationRejected = 'Loading.DeleteReservationRejected',

  SearchReservationsPending = 'Loading.SearchReservationsPending',
  SearchReservationsSuccess = 'Loading.SearchReservationsSuccess',
  SearchReservationsRejected = 'Loading.SearchReservationsRejected',

  SearchReservationsLoadMorePending = 'Loading.SearchReservationsLoadMorePending',
  SearchReservationsLoadMoreSuccess = 'Loading.SearchReservationsLoadMoreSuccess',
  SearchReservationsLoadMoreRejected = 'Loading.SearchReservationsLoadMoreRejected',

  GetReservationsDetailsPending = 'Loading.GetReservationsDetailsPending',
  GetReservationsDetailsSuccess = 'Loading.GetReservationsDetailsSuccess',
  GetReservationsDetailsRejected = 'Loading.GetReservationsDetailRejected',
}

export interface LoadingState {
  is_create_reservation_loading: boolean;
  is_update_reservation_loading: boolean;
  is_delete_reservation_loading: boolean;
  is_search_reservations_loading: boolean;
  is_search_reservations_load_more_loading: boolean;
  is_get_reservations_details_loading: boolean;
}

export interface LoadingActions {
  type:
    | LoadingActionTypes.CreateReservationPending
    | LoadingActionTypes.CreateReservationSuccess
    | LoadingActionTypes.CreateReservationRejected
    | LoadingActionTypes.UpdateReservationPending
    | LoadingActionTypes.UpdateReservationSuccess
    | LoadingActionTypes.UpdateReservationRejected
    | LoadingActionTypes.DeleteReservationPending
    | LoadingActionTypes.DeleteReservationSuccess
    | LoadingActionTypes.DeleteReservationRejected
    | LoadingActionTypes.SearchReservationsPending
    | LoadingActionTypes.SearchReservationsSuccess
    | LoadingActionTypes.SearchReservationsRejected
    | LoadingActionTypes.SearchReservationsLoadMorePending
    | LoadingActionTypes.SearchReservationsLoadMoreSuccess
    | LoadingActionTypes.SearchReservationsLoadMoreRejected
    | LoadingActionTypes.GetReservationsDetailsPending
    | LoadingActionTypes.GetReservationsDetailsSuccess
    | LoadingActionTypes.GetReservationsDetailsRejected;
}
