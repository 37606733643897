export enum PreferencesActionTypes {
  SetDefaultPreferences = 'Preferences.SetDefaultPreferences',
  UpdatePreference = 'Preferences.UpdatePreference',
}

export interface PreferencesState {
  auto_arrival?: boolean;
  slot_guests?: boolean;
  no_show?: boolean;
  canceled?: boolean;
  original_time?: boolean;
  show_stats?: boolean;
  show_phone?: boolean;
  waiting_list?: boolean;
  calendar_full_view?: boolean;
  show_walk_in_on_lists?: boolean;
  show_sidebars?: boolean;
  show_calendar_list_tags?: boolean;
}

export type IPreferenceKey = keyof PreferencesState;

export interface UpdatePreferenceData {
  key: IPreferenceKey;
  value: boolean | string;
}

export type PreferencesActions =
  | {
      type: PreferencesActionTypes.SetDefaultPreferences;
      payload: PreferencesState;
    }
  | {
      type: PreferencesActionTypes.UpdatePreference;
      payload: UpdatePreferenceData;
    };
