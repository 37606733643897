import axios from 'axios';
import {
  getToken,
  removeToken,
  removeTokenFromCookie,
  responseHasTokenError,
} from 'utils/auth/tokenManagement';
import paths from 'app/router/paths';
import { handleErrors } from 'utils/http/handleErrors';

export const baseUrl = process.env.REACT_APP_API_BASE_URL;
const version = 'api-app';

export const axiosConfig = {
  withCredentials: true,
  baseURL: baseUrl + version,
};

export const client = axios.create(axiosConfig);

const onSuccess = function (response: any) {
  return response.data;
};

const onError = function (error: any) {
  const token = getToken();

  if (responseHasTokenError(error?.response) && token) {
    removeTokenFromCookie();
    removeToken();
    window.location.href = paths.auth.login;
  }

  return Promise.reject(error?.response || error?.message || error);
};

/**
 * Request Wrapper with default success/error actions
 */

//TODO remove in future
export const request = function (options: any) {
  const token = getToken();

  if (token) {
    options.headers = {
      Authorization: `Bearer ${token}`,
    };
  }

  return client({
    ...options,
    timeout: process.env.NODE_ENV === 'development' ? 999999 : 7000,
  })
    .then((response) => {
      return onSuccess(response);
    })
    .catch(onError);
};

export const asyncRequest = async (options: any, handleError = false) => {
  try {
    const token = getToken();

    if (token) {
      options.headers = {
        Authorization: `Bearer ${token}`,
        ...options.headers,
      };
    }
    if (process.env.REACT_APP_ENV !== 'production') {
      options.params = {
        ...options.params,
        XDEBUG_SESSION_START: 'PHPSTORM',
      };
    }
    const response = await client({
      ...options,
      timeout: process.env.NODE_ENV === 'development' ? 999999 : 7000,
    });

    return onSuccess(response);
  } catch (error: any) {
    handleError && handleErrors(error);

    throw await onError(error);
  }
};
